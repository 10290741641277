'use client';
import {
	Alert,
	AlertTitle,
	Box,
	Chip,
	Container,
	Divider,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemText,
	Paper,
	Rating,
	Typography,
} from '@mui/material';
import { useClientTranslation, useSessionClient } from '@negotium/common';
import React, { useEffect, useState } from 'react';
import { useNotification } from '../ErrorContext';
import {
	IImageShow,
	IProductCatalog,
	IProductCatalogDetails,
} from '@negotium/models';
import {
	addProductCartShop,
	addUserRateToProduct,
	addUserToWhishList,
	getRelationsProductsByCategories,
} from '@negotium/libCustomer';
import { ImageWithFallback } from '../ImageWithFallback';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { useContextCartShop } from '../ContextCartShop';
import { CardProduct } from '../CardProduct';
import { formatCurrency } from 'apps/customer-portal/utils/numWithComma';
import { ButtonAddProductCardShop } from '../ButtonAddProductCardShop';
import { CardProductHorizontal } from '../CardProductHorizontal';
import { useContextGeneral } from '../ContextGeneral';
import { useRouter } from 'next/navigation';
// import ShareButtons from '../SharedButtons';
import { StarOutlined, StarSolid } from '../Svgs';
import ThemeBasic from 'apps/customer-portal/utils/theme';
import { ArrowBack } from '@mui/icons-material';
import Slider from 'react-slick';

type ProductDetailsProps = {
	lng: 'es' | 'en';
	productId: string;
	productDetails: IProductCatalogDetails;
};

export const ProductDetails = ({
	lng,
	productDetails,
	productId,
}: ProductDetailsProps) => {
	const { t } = useClientTranslation(lng, ['products', 'catalog']);

	const { changeCountProducts, cartShopId, saveCartShopIdLocalStorage } =
		useContextCartShop();
	const [imageSelected, setImageSelected] = useState<IImageShow | null>(null);
	const [products, setProducts] = useState<Array<IProductCatalog>>([]);
	const [rating, setRating] = useState<number>(0);
	const user = useSessionClient();
	const { setShowLogin, calculatePriceFromCurrency, currency } =
		useContextGeneral();
	const { setSuccessNotification, setErrorNotification } = useNotification();
	const router = useRouter();

	const [zoomStyle, setZoomStyle] = useState({});

	const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		const { offsetX, offsetY, target } = e.nativeEvent;

		if (target instanceof HTMLElement) {
			const { offsetWidth, offsetHeight } = target;

			const xPercent = (offsetX / offsetWidth) * 100;
			const yPercent = (offsetY / offsetHeight) * 100;

			setZoomStyle({
				backgroundPosition: `${xPercent}% ${yPercent}%`,
				display: 'block',
			});
		} else {
			console.error('El target no es un elemento HTML válido.');
		}
	};

	const handleMouseLeave = () => {
		setZoomStyle({ display: 'none' });
	};

	useEffect(() => {
		if (
			productDetails?.productCategories &&
			productDetails?.productCategories?.length > 0
		) {
			getRelationsProductsByCategories(
				productDetails.productCategories,
				productId
			)
				.then((result) => {
					setProducts(result.docs);
				})
				.catch(() => {
					setErrorNotification(t('errorToGetProductRelationsByCategories'));
				});
		}
		if (productDetails.productImage?.url) {
			setImageSelected(productDetails.productImage);
		}
		if (productDetails.ratingAverage) {
			setRating(productDetails.ratingAverage);
		}
	}, [productId]);

	function handlerAddProduct(storageUniqueId: string, amount: number) {
		addProductCartShop({ amount, storageUniqueId, cartId: cartShopId })
			.then((response) => {
				changeCountProducts();
				saveCartShopIdLocalStorage(response.data as string);
				setSuccessNotification(
					t('addedProductToCartShopSuccessfully', { ns: 'catalog' })
				);
			})
			.catch(() => {
				setErrorNotification(
					t('errorToAddedProductToCartShop', { ns: 'catalog' })
				);
			});
	}

	function handleSave(amount: number) {
		if (productDetails) {
			handlerAddProduct(productDetails?.storageUniqueId, amount);
		}
	}
	function addOrRemoveFavorite(storageUniqueId: string) {
		if (user) {
			addUserToWhishList(storageUniqueId)
				.then((result) => {
					setSuccessNotification(
						t(
							result?.data
								? 'successToProductAddedWishList'
								: 'successToProductRemoveWishList'
						)
					);
				})
				.catch(() => {
					setErrorNotification(t('errorToProductAddedWishList'));
				});
		} else {
			setShowLogin(true);
		}
	}

	function setWishListUserLogged(data: boolean, index: number) {
		const aux = products;
		aux[index] = {
			...aux[index],
			whishListUserLogged: data,
		};
		setProducts([...aux]);
	}

	function onChange(newValue: number, storageUniqueId: string) {
		if (user) {
			addUserRateToProduct(storageUniqueId, newValue)
				.then((result) => {
					if (result.data) {
						setRating(result.data);
						setSuccessNotification(t('thankForRateUs', { ns: 'catalog' }));
					}
				})
				.catch(() => {
					setErrorNotification(t('errorToRateProduct'));
				});
		} else {
			setShowLogin(true);
		}
	}

	function calculatePercent(price: number, percent: number) {
		return Number(price) - Number(price * percent) / 100;
	}

	if (!productDetails) {
		return (
			<Container
				maxWidth='xl'
				sx={{
					my: 3,
				}}
			>
				<Grid container>
					<Grid item xs={12}>
						<Alert severity='info'>
							<AlertTitle>{t('productNotFound')}</AlertTitle>
						</Alert>
					</Grid>
				</Grid>
			</Container>
		);
	}

	const settings = {
		dots: productDetails?.productImageGallery.length > 1,
		infinite: productDetails?.productImageGallery.length > 1,
		speed: 1000,
		autoplaySpeed: 3000,
		autoplay: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		cssEase: 'linear',
	};

	return (
		<Container
			maxWidth={false}
			disableGutters
			sx={{
				mt: 5,
			}}
		>
			<Grid container spacing={5}>
				<Grid item xs={12}>
					<Container maxWidth='xl'>
						<Grid container spacing={2}>
							<Grid item xs={12} display='flex' alignItems='center'>
								<IconButton
									onClick={() => router.back()}
									sx={{
										mr: 0.4,
									}}
									title={t('back', { ns: 'common' })}
								>
									<ArrowBack color='secondary' />
								</IconButton>
								<Typography variant='TitleSection'>
									{t('detailsOfProducts')}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Paper
									elevation={4}
									sx={{
										p: { md: 3, xs: 2 },
										borderRadius: 5,
										background: (theme) => theme.palette.white?.main,
										boxShadow: {
											md: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
											xs: '0 3px 5px rgba(0,0,0,0.2) ',
										},
									}}
								>
									<Grid container spacing={2}>
										<Grid
											item
											xs={12}
											md={4}
											display={{
												md: 'block',
												xs: 'none',
											}}
										>
											{imageSelected && (
												<Box
													sx={{
														background: (theme) =>
															theme.palette.white?.main || '#ffffff',
														borderRadius: 5,
														overflow: 'hidden',
														position: 'relative',
													}}
												>
													<Box
														width={{ xs: '100%', md: '100%' }}
														onMouseMove={handleMouseMove}
														onMouseLeave={handleMouseLeave}
														sx={{
															borderRadius: 5,
															display: 'flex',
														}}
													>
														<ImageWithFallback
															src={imageSelected?.url || ''}
															fallbackImage='/static/favicon/256x256.png'
															alt={productDetails?.productName[lng] || ''}
															width={imageSelected?.width || 100}
															height={imageSelected?.height || 100}
															style={{
																width: '100%',
																height: '400px',
																display: 'flex',
															}}
														/>
													</Box>
													<div
														className='zoom'
														style={{
															...zoomStyle,
															backgroundImage: `url(${process.env.NEXT_PUBLIC_MEDIA_URL_DISPLAY}${imageSelected?.url})`,
															position: 'absolute',
															top: 0,
															left: 0,
															width: '100%',
															height: '100%',
															backgroundRepeat: 'no-repeat',
															backgroundSize: '180%',
															pointerEvents: 'none',
														}}
													/>
												</Box>
											)}
											{productDetails?.productImageGallery.length > 1 && (
												<Box display='flex'>
													{productDetails?.productImageGallery.map((image) => {
														return (
															<Box
																key={image.url}
																sx={{
																	mx: 1,
																	my: 1,
																	width: 100,
																	height: 100,
																	backgroundImage: `url(${image.url})`,
																	backgroundSize: 'cover',
																	backgroundPosition: 'center',
																	cursor: 'pointer',
																	background: (theme) =>
																		theme.palette.white?.main || '#ffffff',
																	borderRadius: 5,
																	overflow: 'hidden',
																	boxShadow:
																		'0px 0px 10px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 7px 14px rgba(0, 0, 0, 0.1)',
																}}
																onClick={() => setImageSelected(image)}
															>
																<ImageWithFallback
																	src={image?.url || ''}
																	fallbackImage='/static/favicon/256x256.png'
																	alt={image?.url || ''}
																	width={image?.width || 100}
																	height={image?.height || 100}
																	style={{ width: '100%', height: '100%' }}
																/>
															</Box>
														);
													})}
												</Box>
											)}
										</Grid>
										<Grid
											item
											xs={12}
											display={{
												md: 'none',
												xs: 'block',
											}}
											position='relative'
											className='sliderProductImages'
										>
											{/* @ts-expect-error Server Component  */}
											<Slider {...settings}>
												{productDetails?.productImageGallery.length > 0 &&
													productDetails?.productImageGallery.map((image) => (
														<Box
															key={image.url}
															sx={{
																mx: 1,
																my: 1,
																width: '200px',
																minHeight: '250px',
																backgroundImage: `url(${process.env.NEXT_PUBLIC_MEDIA_URL_DISPLAY}${image.url})`,
																backgroundSize: 'cover',
																backgroundPosition: 'center',
																backgroundRepeat: 'no-repeat',
																cursor: 'pointer',

																borderRadius: 5,
																overflow: 'hidden',
															}}
															// onClick={() => setImageSelected(image)}
														></Box>
													))}
											</Slider>
											<IconButton
												aria-label={t('addToWishList')}
												color='secondary'
												size='large'
												title={t('addToWishList')}
												onClick={() =>
													addOrRemoveFavorite(
														productDetails?.storageUniqueId || ''
													)
												}
												sx={{
													display: {
														md: 'none',
														xs: 'block',
													},
													position: 'absolute',
													right: '8px',
													top: '22px',
												}}
											>
												{productDetails?.whishListUserLogged ? (
													<FavoriteIcon fontSize='large' />
												) : (
													<FavoriteBorderIcon fontSize='large' />
												)}
											</IconButton>
										</Grid>
										<Grid
											item
											xs={12}
											md={8}
											sx={{
												px: { md: '30px !important', xs: '10px' },
												display: 'flex',
												flexDirection: 'column',
												justifyContent: 'space-between',
											}}
										>
											<Box>
												<Box
													display='flex'
													justifyContent='space-between'
													alignItems='center'
													mb={3}
												>
													<Rating
														value={rating}
														precision={0.5}
														size='large'
														onChange={(e, newValue) => {
															onChange(
																newValue || 0,
																productDetails.storageUniqueId
															);
														}}
														icon={
															<StarSolid
																color={ThemeBasic.palette.secondary.main}
															/>
														}
														emptyIcon={
															<StarOutlined
																color={ThemeBasic.palette.secondary.main}
															/>
														}
													/>
													<IconButton
														aria-label={t('addToWishList')}
														color='secondary'
														size='large'
														title={t('addToWishList')}
														onClick={() =>
															addOrRemoveFavorite(
																productDetails?.storageUniqueId || ''
															)
														}
														sx={{
															display: {
																md: 'block',
																xs: 'none',
															},
														}}
													>
														{productDetails?.whishListUserLogged ? (
															<FavoriteIcon fontSize='large' />
														) : (
															<FavoriteBorderIcon fontSize='large' />
														)}
													</IconButton>
												</Box>
												<Box
													display='flex'
													justifyContent='space-between'
													alignItems={{ md: 'center', xs: 'flex-start' }}
													flexDirection={{ xs: 'column', md: 'row' }}
												>
													<Typography
														variant='ExplicationText'
														fontSize='clamp(1rem,3vw,1.5rem)'
														fontWeight='500'
													>
														{productDetails?.productName[lng] || ''}
													</Typography>
													{productDetails?.productTag[lng] && (
														<Box display='flex' justifyContent='flex-end'>
															<Chip
																label={productDetails?.productTag[lng]}
																color='success'
																variant='filled'
															/>
														</Box>
													)}
												</Box>
												<Box
													my={{ md: 2, xs: 0.5 }}
													display='flex'
													alignItems='center'
												>
													<Typography
														variant='BodySection'
														fontSize='1rem'
														letterSpacing={1}
														fontWeight='600'
														sx={{
															lineHeight: '1rem',
															...(productDetails.productDiscountPercentage && {
																textDecoration: 'line-through',
																textDecorationColor: '#77160e',
																color: '#77160e',
															}),
														}}
													>
														{`${formatCurrency(
															calculatePriceFromCurrency(
																productDetails?.productSalePrice
															),
															currency || 'USD'
														)} ${currency || 'USD'}`}
													</Typography>
													{productDetails.productDiscountPercentage > 0 && (
														<Typography
															variant='ExplicationText'
															fontSize='clamp(0.7rem,3.5vw,1rem)'
															color='secondary.main'
															fontWeight='600'
															sx={{
																lineHeight: 0,
																ml: 1,
															}}
														>
															{`${formatCurrency(
																calculatePriceFromCurrency(
																	calculatePercent(
																		productDetails.productSalePrice,
																		productDetails.productDiscountPercentage
																	)
																),
																currency || 'USD'
															)} ${currency || 'USD'}`}
														</Typography>
													)}
												</Box>
												<Box>
													<Divider
														sx={{
															borderColor: (theme) =>
																theme.palette.secondary.main,
														}}
													/>
												</Box>
												<Box my={2}>
													<Typography
														variant='ExplicationText'
														fontWeight='500'
														fontSize='clamp(0.9rem,3vw,1.2rem)'
														color='black'
													>
														{productDetails?.productDetails[lng] || ''}
													</Typography>
													<List
														sx={{
															display: 'flex',
															flexWrap: 'wrap',
															width: '100%',
														}}
													>
														{productDetails.attributes?.map((attribute) => {
															return (
																<ListItem
																	key={attribute.attributeId}
																	sx={{
																		pl: 0,
																		width: 'max-content',
																		py: {
																			md: 1,
																			xs: 0,
																		},
																	}}
																>
																	<ListItemText
																		sx={{
																			display: 'flex',
																			alignItems: 'flex-start',
																		}}
																		primary={
																			attribute.attributeName[lng]
																				? `${attribute.attributeName[lng]}: `
																				: ''
																		}
																		primaryTypographyProps={{
																			fontWeight: '600',
																			mr: '3px',
																			mt: 0,
																			fontSize: '14px',
																			variant: 'body1',
																		}}
																		secondaryTypographyProps={{
																			mr: '3px',
																			mt: 0,
																			fontSize: '14px',
																			variant: 'body1',
																		}}
																		secondary={attribute.attributeValue?.join(
																			', '
																		)}
																	/>
																</ListItem>
															);
														})}
													</List>
												</Box>
												<Box>
													<Divider
														sx={{
															borderColor: (theme) =>
																theme.palette.secondary.main,
														}}
													/>
												</Box>
											</Box>

											<Box
												sx={{
													display: 'flex',
													justifyContent: 'space-between',
													alignItems: 'center',
													flexWrap: 'wrap',
													flexDirection: {
														md: 'row',
														xs: 'column-reverse',
													},
													mt: 3,
												}}
											>
												<Box
													sx={{
														display: 'flex',
 
														justifyContent: 'center',
														my: { md: 0, xs: 1 },
														width: { md: 'auto', xs: '95%' },
													}}
												>
													<ButtonAddProductCardShop
														lng={lng}
														onCallback={handleSave}
														justifyContent='flex-end'
													/>
												</Box>
											</Box>
										</Grid>
									</Grid>
								</Paper>
							</Grid>
						</Grid>
					</Container>
				</Grid>
				{products.length > 0 && (
					<Grid item xs={12}>
						<Container
							maxWidth={false}
							sx={{
								background: (theme) => theme.palette.primary.light,
								mt: 3,
								pt: 5,
								pb: 7,
							}}
						>
							<Container maxWidth='xl'>
								<Grid container spacing={3}>
									<Grid item xs={12}>
										<Typography variant='TitleSection' mb={2}>
											{t('relationsProducts')}
										</Typography>
									</Grid>

									{products.map((product, index) => (
										<Grid
											item
											xs={12}
											sm={4}
											md={3}
											lg={2.3}
											key={product.productId}
											sx={{
												minHeight: '100%',
											}}
										>
											<Box
												sx={{
													display: {
														md: 'block',
														xs: 'none',
													},
												}}
											>
												<CardProduct
													product={product}
													lng={lng}
													onAddProduct={handlerAddProduct}
													setWishListUserLogged={(data) =>
														setWishListUserLogged(data, index)
													}
												/>
											</Box>
											<Box
												sx={{
													display: {
														xs: 'block',
														md: 'none',
													},
												}}
											>
												<CardProductHorizontal
													product={product}
													lng={lng}
													onAddProduct={handlerAddProduct}
													setWishListUserLogged={(data) =>
														setWishListUserLogged(data, index)
													}
												/>
											</Box>
										</Grid>
									))}
								</Grid>
							</Container>
						</Container>
					</Grid>
				)}
			</Grid>
		</Container>
	);
};
