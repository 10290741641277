import {
	Box,
	Button,
	CircularProgress,
	Container,
	Grid,
	Paper,
	Typography,
} from '@mui/material';
import { useClientTranslation } from '@negotium/common';
import { getPointUser } from '@negotium/libCustomer';
import { useNotification } from 'apps/customer-portal/components/ErrorContext';
import React, { useEffect, useState } from 'react';
import { RedeemPoints } from './RedeemPoints';

type Props = {
	lng: 'es' | 'en';
	onRefreshBalance: () => void;
};

export const PointsUsers = ({ lng, onRefreshBalance }: Props) => {
	const [pointUsers, setPointUsers] = useState<number>(0);
	const { t } = useClientTranslation(lng, ['wallet']);
	const { setErrorNotification } = useNotification();
	const [showRedeemPoints, setShowRedeemPoints] = useState<boolean>(false);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchPointUsers = async () => {
			try {
				const response = await getPointUser();
				setPointUsers(response.data);
			} catch (error) {
				setErrorNotification(
					error instanceof Error ? error.message : 'Unknown error'
				);
			} finally {
				setLoading(false);
			}
		};
		if (loading) {
			fetchPointUsers();
		}
	}, [loading]);

	function handlerRefreshPointAndBalance() {
		setLoading(true);
		onRefreshBalance();
	}

	return (
		<Paper
			elevation={2}
			sx={{
				mt: 2,
				p: { md: 4, xs: 2 },
				borderRadius: 5,
				overflow: { md: 'auto' },
			}}
		>
			<Container maxWidth='xl' disableGutters>
				<Grid container>
					<Grid item xs={12}>
						<Typography
							variant='ExplicationTextBold'
							fontSize='clamp(1rem, 3vw, 1.5rem)'
							mb={2}
							textAlign={{
								sm: 'left',
								xs: 'center',
							}}
						>
							{t('availablePoint')}
						</Typography>
						<Typography variant='ExplicationTextBold' mb={1}>
							{t('pointUsersDescription')}
						</Typography>
						{loading && (
							<Box display='flex' justifyContent='center' mt={4}>
								<CircularProgress sx={{ color: 'white' }} />
							</Box>
						)}{' '}
						{!loading && (
							<Box
								display='flex'
								justifyContent='space-between'
								alignItems='center'
								flexDirection={{
									sm: 'row',
									xs: 'column',
								}}
								mt={4}
								mb={3}
							>
								<Typography
									variant='ExplicationTextBold'
									fontWeight='bold'
									fontSize='clamp(1.6rem, 3vw, 2rem)'
									color='black'
									textAlign='center'
									marginBottom={{
										xs: 1,
										sm: 0,
									}}
								>
									{pointUsers}
								</Typography>
								<Button
									variant='outlined'
									color='secondary'
									onClick={() => setShowRedeemPoints(true)}
								>
									{t('redeem')}
								</Button>
							</Box>
						)}
					</Grid>
				</Grid>
			</Container>
			{showRedeemPoints && (
				<RedeemPoints
					lng={lng}
					open={showRedeemPoints}
					onClose={() => setShowRedeemPoints(false)}
					onRefreshBalance={handlerRefreshPointAndBalance}
				/>
			)}
		</Paper>
	);
};
