import {
	Box,
	Button,
	Chip,
	CircularProgress,
	Container,
	Grid,
	List,
	ListItem,
	ListItemText,
	Pagination,
	Paper,
	Typography,
} from '@mui/material';
import { useClientTranslation } from '@negotium/common';
import { TransactionWallet } from '@negotium/models';
import { getHistoricalWallet } from 'apps/customer-portal/app/lib/wallet/actions';
import { useNotification } from 'apps/customer-portal/components/ErrorContext';
import { formatCurrency } from 'apps/customer-portal/utils/numWithComma';
import { useRouter } from 'next/navigation';
import React, { useEffect, useState } from 'react';

type Props = {
	lng: 'es' | 'en';
	limit?: number;
	showTitle?: boolean;
	showPaginate?: boolean;
};

export const History = ({
	lng,
	limit = 5,
	showTitle = true,
	showPaginate = false,
}: Props) => {
	const [historical, setHistorical] = useState<TransactionWallet[]>([]);
	const { t } = useClientTranslation(lng, ['wallet']);
	const { setErrorNotification } = useNotification();
	const [total, setTotal] = useState<number>(0);
	const [loading, setLoading] = useState(true);
	const router = useRouter();
	const [skip, setSkip] = useState<number>(0);

	useEffect(() => {
		const fetchHistoricalCredit = async () => {
			try {
				const response = await getHistoricalWallet(skip, limit);
				setHistorical(response.data.transactions);
				setTotal(response.data.total);
			} catch (error) {
				setErrorNotification(
					t(error instanceof Error ? error.message : 'unknownError')
				);
			} finally {
				setLoading(false);
			}
		};

		fetchHistoricalCredit();
	}, [skip]);

	const handlePageChange = (value: number) => {
		setSkip(value - 1);
	};

	const getTypeColor = (type: TransactionWallet['type']) => {
		switch (type) {
			case 'deposit':
				return 'success';
			case 'withdraw':
				return 'error';
			case 'purchase':
				return 'warning';
			default:
				return 'default';
		}
	};

	return (
		<Paper
			elevation={2}
			sx={{
				mt: 2,
				p: { md: 4, xs: 2 },
				borderRadius: 5,
				overflow: { md: 'auto' },
			}}
		>
			<Container maxWidth='xl' disableGutters>
				<Grid container>
					{showTitle && (
						<Grid
							item
							xs={12}
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
								flexDirection: { md: 'row', xs: 'column' },
							}}
						>
							<Typography
								variant='ExplicationTextBold'
								fontSize='clamp(1rem, 3vw, 1.5rem)'
								mb={2}
								textAlign={{
									sm: 'left',
									xs: 'center',
								}}
							>
								{t('historicalCredit')}
							</Typography>
							<Button
								variant='text'
								color='info'
								sx={{
									mb: 2,
								}}
								onClick={() => router.push(`/${lng}/account/wallet/historical`)}
							>
								{t('historicalComplete')}
							</Button>
						</Grid>
					)}

					{loading && (
						<Grid item xs={12}>
							<Box display='flex' justifyContent='center' mt={4}>
								<CircularProgress sx={{ color: 'white' }} />
							</Box>
						</Grid>
					)}
					{!loading && historical.length > 0 && (
						<>
							<Grid item xs={12}>
								<List>
									{historical.map((transaction, index) => (
										<ListItem
											key={`${index}_${transaction.date}`}
											divider
											sx={{
												flexDirection: {
													sm: 'row',
													xs: 'column',
												},
											}}
										>
											<ListItemText
												primary={
													<Typography variant='ExplicationText'>
														{transaction.description || 'Sin descripción'}
													</Typography>
												}
												secondary={
													<>
														<Typography
															variant='ExplicationText'
															color='text.secondary'
														>
															{new Date(transaction.date).toLocaleString()}
														</Typography>
														<Typography
															variant='ExplicationTextBold'
															fontWeight='bold'
														>
															{`${
																transaction.type !== 'deposit' ? '-' : ''
															} ${formatCurrency(transaction.amount)}`}
														</Typography>
													</>
												}
											/>
											<Chip
												sx={{
													minWidth: '100px',
												}}
												label={t(transaction.type)}
												color={getTypeColor(transaction.type)}
											/>
										</ListItem>
									))}
								</List>
							</Grid>
							{showPaginate && total > limit && (
								<Grid
									item
									xs={12}
									display='flex'
									justifyContent={{ sm: 'flex-end', xs: 'center' }}
									marginTop={1}
								>
									<Pagination
										count={Math.ceil(total / limit)}
										page={skip + 1}
										onChange={(_, page) => handlePageChange(page)}
										color='primary'
										// showFirstButton
										// showLastButton
									/>
								</Grid>
							)}
						</>
					)}
					{!loading && historical.length === 0 && (
						<Grid item xs={12}>
							<Box display='flex' justifyContent='center' mt={4}>
								<Typography variant='ExplicationTextBold'>
									{t('noTransactionsCredit')}
								</Typography>
							</Box>
						</Grid>
					)}
				</Grid>
			</Container>
		</Paper>
	);
};
