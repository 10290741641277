'use client';

import { useState, useEffect } from 'react';
import {
	Typography,
	Grid,
	Box,
	Paper,
	Container,
	CircularProgress,
	Button,
} from '@mui/material';
import { formatCurrency } from 'apps/customer-portal/utils/numWithComma';
import { Wallet as WalletIcon } from '@mui/icons-material';
import { useClientTranslation } from '@negotium/common';
import { useNotification } from '../ErrorContext';
import { PointsUsers } from './components/Points';
import { LinkShare } from './components/LinkShare';
import { getBalanceWallet } from 'apps/customer-portal/app/lib/wallet/actions';
import { History } from './components/History';
import { useRouter } from 'next/navigation';

const WalletBalance = ({ lng }: { lng: 'es' | 'en' }) => {
	const { t } = useClientTranslation(lng, ['wallet']);
	const { setErrorNotification } = useNotification();
	const [loading, setLoading] = useState(true);
	const router = useRouter();

	const [balance, setBalance] = useState<number>(0);

	useEffect(() => {
		const fetchWallet = async () => {
			try {
				const response = await getBalanceWallet();
				setBalance(response.data as number);
			} catch (error) {
				setErrorNotification(
					error instanceof Error ? error.message : 'Unknown error'
				);
			} finally {
				setLoading(false);
			}
		};
		if (loading) {
			fetchWallet();
		}
	}, [loading]);

	return (
		<Grid container spacing={2}>
			<Grid
				item
				xs={12}
				display={{
					md: 'block',
					xs: 'none',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<WalletIcon
						fontSize='large'
						sx={{
							marginRight: '1rem',
							color: (theme) => theme.palette.secondary.main,
						}}
					/>
					<Typography variant='TitleSection'>{t('wallet')}</Typography>
				</Box>
			</Grid>
			<Grid item xs={12}>
				<Paper
					elevation={2}
					sx={{
						mt: 2,
						p: { md: 4, xs: 2 },
						borderRadius: 5,
						overflow: { md: 'auto' },
					}}
				>
					<Container maxWidth='xl' disableGutters>
						<Grid container>
							<Grid item xs={12} sm={8}>
								<Typography
									variant='ExplicationTextBold'
									fontSize='clamp(1rem, 3vw, 1.5rem)'
									mb={2}
									textAlign={{
										sm: 'left',
										xs: 'center',
									}}
								>
									{t('balanceWallet')}
								</Typography>
								<Typography variant='ExplicationTextBold' mb={0}>
									{t('descriptionBalanceWallet')}
								</Typography>
								<Typography variant='ExplicationTextBold' mb={1}>
									{t('descriptionBalanceWallet2')}
								</Typography>
								{loading && (
									<Box display='flex' mt={4}>
										<CircularProgress sx={{ color: 'white' }} />
									</Box>
								)}{' '}
								{!loading && (
									<Typography
										variant='ExplicationTextBold'
										fontWeight='bold'
										fontSize='clamp(1.6rem, 3vw, 2rem)'
										mt={4}
										mb={3}
										color='black'
										textAlign={{
											sm: 'left',
											xs: 'center',
										}}
									>
										{formatCurrency(balance)}
									</Typography>
								)}
							</Grid>
							<Grid item xs={12} sm={4}>
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'center',
										justifyContent: 'center',
										height: '100%',
									}}
								>
									<Button
										variant='ButtonAcceptDialog'
										color='secondary'
										onClick={() => {
											router.push(`/${lng}/account/wallet/transfer`);
										}}
										title={t('transferFunds')}
										sx={{
											minWidth: '200px',
										}}
									>
										{t('transferFunds')}
									</Button>{' '}
									<Button
										variant='ButtonCancelDialog'
										color='secondary'
										onClick={() =>
											router.push(`/${lng}/account/wallet/historical`)
										}
										title={t('historicalComplete')}
										sx={{
											mt: 2,
											minWidth: '200px',
											textTransform: 'inherit',
											background: 'transparent',
										}}
									>
										{t('historicalComplete')}
									</Button>
								</Box>
							</Grid>
						</Grid>
					</Container>
				</Paper>
			</Grid>
			<Grid item xs={12} sm={5}>
				<PointsUsers lng={lng} onRefreshBalance={() => setLoading(true)} />
			</Grid>
			<Grid item xs={12} sm={7}>
				<LinkShare lng={lng} />
			</Grid>
			<Grid item xs={12}>
				<History lng={lng} limit={5} />
			</Grid>
		</Grid>
	);
};

export default WalletBalance;
